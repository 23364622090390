import { Image } from "antd";
import { useTranslation } from "react-i18next";

export default function Company() {
  const data = [
    {
      image: "/static/images/bank/paypal.png",
      alt: "paypal",
    },
    {
      image: "/static/images/bank/mastercard.png",
      alt: "mastercard",
    },
    {
      image: "/static/images/bank/visa.png",
      alt: "visa",
    },
    {
      image: "/static/images/bank/maestro.png",
      alt: "maestro",
    },
    {
      image: "/static/images/bank/sofort.png",
      alt: "sofort",
    },
    {
      image: "/static/images/bank/googlepay.png",
      alt: "googlepay",
    },
    {
      image: "/static/images/bank/applepay.png",
      alt: "applepay",
    },
    {
      image: "/static/images/bank/mangopay.png",
      alt: "mangopay",
    },
  ];

  const { t } = useTranslation();
  return (
    <div className="flex flex-col md:px-0  md:self-start  text-left">
      <p className="font-bold text-lg mb-[8px]">{t("main.Sichere_Zahlung")}</p>
      <div className="w-[75%] xl:w-[70%] grid grid-cols-4 gap-1">
        {data?.map((logo) => (
          <div
            key={"secure payment method " + logo.alt}
            className={`${
              logo.alt === "mangopay"
                ? "col-span-2 bg-[#fcb069]"
                : "col-span-1 bg-white"
            } rounded-xl flex items-center justify-center`}
          >
            <Image
              className={`rounded-xl h-full w-full ${
                logo.alt === "mangopay"
                  ? "object-contain"
                  : "object-cover object-center"
              }`}
              src={logo.image}
              alt={logo.alt + " icon"}
              loading="lazy"
              preview={false}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
