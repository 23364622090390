import AuthController from "@controller/auth/AuthController";
import { Modal } from "antd";
import { inject, observer } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import LoginForms from "./LoginForms";
import useWindowSize from "src/hooks/useWindowSize";

const controller = new AuthController();

const login = inject("CoreStore")(
  observer(({ open, handleclose, CoreStore, viewMod = "login", router }) => {
    const { t } = useTranslation("common");
    const { width } = useWindowSize();
    const isMobile = width < 768;

    useEffect(() => {
      controller.setRouter(router);
    }, [router]);

    useEffect(() => {
      if (
        CoreStore.offerCardIdSlug !== "" &&
        CoreStore.offerCardIdSlug != undefined
      ) {
        controller.isFromOfferPage = true;
        return (controller.offerCardId = CoreStore.offerCardIdSlug);
      }
    }, [CoreStore.offerCardIdSlug]);

    useEffect(() => {
      if (viewMod == "register") {
        setTimeout(() => controller.changeTab("register"), 100);
      }
      controller.viewMod = viewMod;
    }, []);

    useEffect(() => {
      controller.setAfterLogin((data) => _loginCoreSet(data));
    }, []);

    useEffect(() => {
      controller.closeModal();
    }, [open]);

    useEffect(() => {
      if (controller.showEmailRegisterSuccessModal) {
        const closeSuccessModal = setTimeout(() => {
          controller.showEmailRegisterSuccessModal = false;
          handleclose(false);
        }, 6000);

        return () => clearTimeout(closeSuccessModal);
      }
    }, [controller.showEmailRegisterSuccessModal]);

    const _loginCoreSet = (data) => {
      CoreStore.userDataSet(data);
      CoreStore.setToken("A");
      CoreStore.loginModalAction(false);
    };

    useEffect(() => {
      const keyDownHandler = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
        }
      };

      document.addEventListener("keydown", keyDownHandler);

      return () => {
        document.removeEventListener("keydown", keyDownHandler);
      };
    }, []);

    return (
      <>
        <Modal
          open={open}
          onCancel={() => {
            controller.closeModal();
            handleclose(false);
            CoreStore.loginFromOfferPage = false;
          }}
          width={isMobile ? "100%" : "600px"}
          destroyOnClose={true}
          wrapClassName={
            isMobile
              ? "rounded-none -mt-4 bg-white !-bottom-5 mobile-modal"
              : "rounded-2xl"
          }
          className={`${isMobile ? "modal-login !pb-0 !mb-0" : "auth-modal"}`}
          footer={null}
          centered
        >
          <div
            className={"flex flex-col py-3 sm:px-6 min-h-screen md:min-h-fit"}
          >
            <div className={"flex flex-col mt-1 h-full"}>
              <LoginForms
                mode={controller.viewMod}
                controller={controller}
                fromOfferPage={CoreStore.loginFromOfferPage}
              />
            </div>
          </div>
        </Modal>

        <Modal
          open={controller.showEmailRegisterSuccessModal}
          onCancel={() => (controller.showEmailRegisterSuccessModal = false)}
          centered
          footer={false}
        >
          <div className="w-full flex flex-col items-center justify-center px-2 md:px-4 py-5">
            <img
              src={"/static/images/confirm.svg"}
              alt="Successful register"
              className="!w-[150px]"
            />
            <p className="text-base font-medium text-center !mt-6">
              {t("main.after_register_success_message")}
            </p>
          </div>
        </Modal>
      </>
    );
  })
);

export default login;
