import React from 'react'

const Placeholder = () => {
  return (
    <div className="w-full h-[250px]">
      {/* Top */}
      <div className="w-full flex items-center justify-between" />

      {/* Loader */}
      <div className="w-full h-full flex justify-center items-center">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

export default Placeholder