import moment from "moment";
import i18next from "../../../i18next";

class DateHelper {
  constructor() {}

  static getFormat() {
    return i18next.language == "de" ? "DD.MM.YYYY" : "DD/MM/YYYY";
  }
  static dateFormat(date) {
    const format = i18next.language == "de" ? "DD.MM.YYYY" : "DD/MM/YYYY";

    try {
      return moment(date).format(format);
    } catch (e) {
      return "n/a";
    }
  }
}

export default DateHelper;
