import { Button, Empty } from "antd";
import { Component, Fragment } from "react";
import THelper from "@helper/THelper";
// import { Player } from "@lottiefiles/react-lottie-player";
import classNames from "classnames";
import PropTypes from "prop-types";
import Erroranim from "../../../public/static/jsonl/error.json";
import LoadingAnim from "../../../public/static/jsonl/loading.json";
import dynamic from 'next/dynamic';
const Player = dynamic(() => import('@lottiefiles/react-lottie-player').then((mod) => mod.Player), {
  ssr: false,
});

export default class StateView extends Component {
  static get State() {
    return {
      loading: "0",
      placeholder: "1",
      error: "2",
      content: "3",
      privacy: "4",
    };
  }

  constructor(props) {
    super(props);
    this.renderView = this.renderView.bind(this);
  }

  _privacyView = () => {
    if (!!this.props.privacyView) {
      return (
        <Fragment>
          <div className={"full-flex"}>{this.props.privacyView}</div>
        </Fragment>
      );
    } else {
      return null;
    }
  };

  _loadingView = () => {
    if (!!this.props.loadingView) {
      return (
        <Fragment>
          <div className={"full-flex " + this.props.className}>
            {this.props.loadingView}
          </div>
        </Fragment>
      );
    } else {
      return (
        <div
          className={
            "flex flex-col py-5 items-center px-10 w-full h-full justify-center"
          }
        >
          <div className="!w-[350px] !h-[197px] !min-w-[350px]">
            <Player
              autoplay
              loop
              src={LoadingAnim}
              style={{ height: "197px", width: "350px" }}
              className="!w-[350px] !h-[197px] !min-w-[350px]"
            ></Player>
          </div>
        </div>
      );
    }
  };

  _errorView = () => {
    if (!!this.props.errorView) {
      return (
        <Fragment>
          <div className={"styles.container  " + this.props.containerStyle}>
            {this.props.errorView}
          </div>
        </Fragment>
      );
    } else {
      return (
        <div className={"py-20"}>
          <div className={"flex flex-col items-center"}>
            <Player
              autoplay
              loop
              src={Erroranim}
              style={{ height: "200px", width: "200px" }}
            ></Player>

            <h4 className={"font-bold text-base text-red capitalize text-center"}>
              {THelper.t("global.error_state_view_title")}
            </h4>
            <p className={"py-4 text-center"}>
              {THelper.t("global.error_state_view_text")}
            </p>
            <Button
              className={"btn-orange px-10 mt-5"}
              onClick={this.props.errorButtonAction}
            >
              {THelper.t("global.try_againStateView")}{" "}
            </Button>
          </div>
        </div>
      );
    }
  };

  _placeholderView = () => {
    if (!!this.props.placeholderView) {
      return (
        <Fragment>
          <div className={"full-w"}>{this.props.placeholderView}</div>
        </Fragment>
      );
    } else {
      return <Empty />;
    }
  };
  _contentView = () => {
    if (!!this.props.placeholderView) {
      return (
        <Fragment>
          <div className={classNames("full-w ", this.props.className)}>
            {this.props.children}
          </div>
        </Fragment>
      );
    } else {
      return null;
    }
  };

  renderView() {
    switch (this.props.state) {
      case StateView.State.loading:
        return this._loadingView();
      case StateView.State.privacy:
        return this._privacyView();
      case StateView.State.error:
      case StateView.State.offline:
        return this._errorView();
      case StateView.State.placeholder:
        return this._placeholderView();
      case StateView.State.content:
        return (
          <Fragment>
            <div className={"full-w " + this.props.bodyClass}>
              {this.props.children}
            </div>
          </Fragment>
        );
      default:
    }
  }

  render() {
    return this.renderView();
  }
}

StateView.propTypes = {
  state: PropTypes.oneOf(
    Object.keys(StateView.State).map((k) => StateView.State[k])
  ),

  color: PropTypes.string,
  privacyView: PropTypes.element,

  loadingView: PropTypes.element,
  loadingTitle: PropTypes.string,

  placeholderView: PropTypes.element,
  placeholderImageRes: PropTypes.number,
  placeholderTitle: PropTypes.string,
  placeholderBody: PropTypes.string,
  placeholderButtonText: PropTypes.string,
  placeholderButtonAction: PropTypes.func,
  className: PropTypes.string,

  errorView: PropTypes.element,
  errorImageRes: PropTypes.number,
  errorTitle: PropTypes.string,
  errorBody: PropTypes.string,
  errorButtonText: PropTypes.string,
  errorButtonAction: PropTypes.func,
};

StateView.defaultProps = {
  className: "",
  containerStyle: {},
  state: StateView.State.loading,

  color: "#000",
  imageStyle: {},
  titleStyle: {},
  bodyClass: "",
  buttonStyle: {},
  buttonTextStyle: {},

  privacyView: undefined,
  loadingView: undefined,
  loadingTitle: "",
  loadingTitleStyle: {},

  placeholderView: undefined,
  placeholderImageRes: undefined,
  placeholderTitle: "",
  placeholderBody: "",
  placeholderButtonText: "",
  placeholderButtonAction: () => { },
  placeholderImageStyle: {},
  placeholderTitleStyle: {},
  placeholderBodyStyle: {},
  placeholderButtonStyle: {},
  placeholderButtonTextStyle: {},

  errorView: undefined,
  errorImageRes: undefined,
  errorTitle: "",
  errorBody: "",
  errorButtonText: THelper.t("global.try_againStateView"),
  errorButtonAction: () => { },
  errorImageStyle: {},
  errorTitleStyle: {},
  errorBodyStyle: {},
  errorButtonStyle: {},
  errorButtonTextStyle: {},
};
