import AuthController from "@controller/auth/AuthController";
import { useGoogleOneTapLogin } from "@react-oauth/google";
import { message } from "antd";
import jwt from "jsonwebtoken";

const controller = new AuthController();

const GoogleAutoLogin = ({ router }) => {
  function decodeCredentialResponse(credential) {
    var base64Url = credential.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  const generateToken = () => {
    const payload = {
      username: "google_user",
      role: "Auth",
    };
    const secretKey = "moverii_secret_key_google_auth";
    const options = {
      expiresIn: "1h",
    };
    const token = jwt.sign(payload, secretKey, options);
    return token;
  };

  useGoogleOneTapLogin({
    flow: "auth-code",
    auto_select: false,
    onSuccess: (credentialResponse) => {
      const decodeCredential = decodeCredentialResponse(
        credentialResponse.credential
      );

      const token = generateToken();
      const myHeaders = new Headers();
      myHeaders.append("Google-Token", token);

      const formdata = new FormData();
      formdata.append("email", decodeCredential?.email);
      formdata.append("name", decodeCredential?.name);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(
        `${process.env.NEXT_PUBLIC_REST_API_ENDPOINT}google-auth-service`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const res = JSON.parse(result);

          if (res?.httpStatus == 200) {
            message.success("");
            controller.setTokenCookies(res?.token);
            controller.getUserInfo();
            router.reload();
          }
        })
        .catch((error) => {
          console.error(error?.response);
          message.error("Something went wrong, please try again!");
        });
    },
    onError: () => {
      message.error("Something went wrong, please try again!");
    },
  });

  return <></>;
};

export default GoogleAutoLogin;
