import Link from "next/link";
export default function Links({ items }) {
  return (
    <div className="block">
      <div className="font-bold text-lg  pb-3">{items.title}</div>
      {items.data.map((v, i) => {
        return (
          <div className="flex align-center mb-2 item-links" key={"fm" + i}>
            <Link href={v.link}>
              <a className={"hover:text-orange   focus:text-orange "}>
                <p className="txt-links">{v.txt}</p>
              </a>
            </Link>
          </div>
        );
      })}
    </div>
  );
}
