import { CoreApi } from "../core/CoreApi";
import { API_ENDPOINTS } from "@libs/request/endpoints_helper";

class UserRequest extends CoreApi {
  info(input) {
    return this.http.get(API_ENDPOINTS.UserInfo, input).then((res) => res.data);
  }
}

export const UserService = new UserRequest("auth");
