import { getLanguage, getToken, getUserCurrency } from "./get-token";
import axios from "axios";
import Cookies from "js-cookie";

const request = axios.create({
  baseURL: process.env.NEXT_PUBLIC_REST_API_ENDPOINT,
  timeout: 10000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const userCurrency = getUserCurrency();

request.interceptors.request.use(
  (config) => {
    const token = getToken();
    const lang = getLanguage();

    config.headers = {
      ...config.headers,
      lang: lang,
      Authorization: `Bearer ${token ? token : ""}`,
      currency: userCurrency || "EUR",
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      (error.response && error.response.status === 401) ||
      (error.response && error.response.status === 403) ||
      (error.response && error.response.data.message === ":)")
    ) {
      Cookies.remove("token");
      Cookies.remove("isAuth");
      Cookies.remove("user");
    }
    return Promise.reject(error);
  }
);

export default request;
