import { Image } from "antd";

export default function SocialBox() {
  const icons = [
    {
      image: "/main/instagram.svg",
      link: "https://www.instagram.com/moverii/",
      alt: "instagram icon",
    },
    {
      image: "/main/facebook.svg",
      link: "https://www.facebook.com/moverii/",
      alt: "facebook icon",
    },
    {
      image: "/main/youtube.svg",
      link: "https://www.youtube.com/channel/UCkyVijJkqiIutKQacT3yP7w",
      alt: "youtube icon",
    },
  ];
  return (
    <div className="flex flex-col">
      <div className={"flex flex-row space-x-4 mt-0"}>
        {icons.map((v, i) => {
          return (
            <div className={"flex self-center"} key={"fsocial" + i}>
              <a href={v.link} target={"_blank"}>
                <Image
                  src={v.image}
                  alt={v.alt}
                  width={28}
                  height={"100%"}
                  loading="lazy"
                  className="mr-1 ml-0 cursor-p"
                />
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}
