import { Image } from "antd";
import { useTranslation } from "react-i18next";
export default function Provide() {
  const { t } = useTranslation();
  const s = "common:main.";
  return (
    <div className="flex flex-col w-full items-start  self-start md:self-center text-center md:text-left">
      <div className="font-bold text-lg">{t("main.for_host")}</div>
      <div className="mt-2 mb-[28px]">
        <a
          href={"https://provider.moverii.de"}
          className={"text-white hover:text-white focus:text-white"}
        >
          {t("main.work_with_us")}
        </a>
      </div>

      <div className="font-bold text-lg">{t("main.vertrauen")}</div>
      <div className="flex w-full md:align-center justify-start self-start md:self-center mt-[8px]">
        <Image
          height={"100%"}
          className="mr-2 object-cover object-center"
          src="/main/provid1.svg"
          alt="provider image 1"
          loading="lazy"
        />
        {/* <img
          style={{ height: "100%" }}
          className="mr-2"
          src="/main/provid1.svg"
          alt="provider image 1"
        /> */}
        <Image
          height={"100%"}
          className="w-[90px] h-16 mt-2 object-cover object-center"
          src="/main/provid2.png"
          alt="provider image 2"
          loading="lazy"
        />
        {/* <img
          src="/main/provid2.png"
          alt="provider image 2"
          className="w-[90px] h-16 mt-2"
        /> */}
      </div>
    </div>
  );
}
